import videojs from 'video.js';
import '../../../../../../../node_modules/videojs-youtube/dist/Youtube.min.js';
import Gallery from '../components/_gallery-tabs-gallery';
import GalleryJournal from '../components/_gallery-tabs-journal.js';

class GalleryTabs {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.buttonEls = this.root.querySelectorAll('[data-gallery-tab]');
        this.contentEls = this.root.querySelectorAll('[data-gallery-content]');
        this.galleryEls = this.root.querySelectorAll(
            '[data-js-gallery]:not(.-journal)'
        );
        this.videoEls = this.root.querySelectorAll('[data-video-js]');
        this.journalEls = this.root.querySelectorAll('[data-js-journal]');

        document.addEventListener("gesturestart", function (e) {
            e.preventDefault();
            document.body.style.zoom = 1;
        });
        
        document.addEventListener("gesturechange", function (e) {
            e.preventDefault();
        
          document.body.style.zoom = 1;
        });
        document.addEventListener("gestureend", function (e) {
              e.preventDefault();
            document.body.style.zoom = 1;
        });

        return true;
    }

    init() {
        this.setTabButtons();
        this.setGalleries();
        this.setVideos();
        this.setJournals();
    }

    setTabButtons() {
        if (!this.buttonEls) return;

        this.onTab = this.onTabButton.bind(this);
        this.buttonEls.forEach(item =>
            item.addEventListener('click', this.onTab)
        );
    }

    setGalleries() {
        if (!this.galleryEls) return;
        this.galleryEls.forEach(item => new Gallery(item));
    }

    setJournals() {
        if (!this.journalEls) return;
        this.journalEls.forEach(item => new GalleryJournal(item));
    }

    onTabButton(event) {
        const { currentTarget } = event;
        const index = parseInt(currentTarget.getAttribute('data-gallery-tab'));
        const activeContent = this.root.querySelector(
            `[data-gallery-content="${index}"]`
        );

        this.resetTabs();
        this.resetContent();

        currentTarget.classList.add('-active');
        if (activeContent) activeContent.classList.add('-active');
    }

    setVideos() {
        if (!this.videoEls) return;
        this.videoEls.forEach(item => videojs(item));
    }

    resetTabs() {
        if (!this.buttonEls) return;
        this.buttonEls.forEach(item => item.classList.remove('-active'));
    }

    resetContent() {
        if (!this.contentEls) return;
        this.contentEls.forEach(item => item.classList.remove('-active'));
    }
}

function initGalleryTabs() {
    const sections = document.querySelectorAll('[data-gallery-tabs]');
    if (!sections) return;

    sections.forEach(item => new GalleryTabs(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initGalleryTabs();
} else {
    document.addEventListener('DOMContentLoaded', initGalleryTabs);
}
